.main-panel {
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    transition: right 0.4s ease-in-out;
    .btn-collapse {
        min-width: 20px;
        height: 60px;
        background-color: #1890FF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 40px;
        padding: 16px 6px;
        border-radius: 6px 0px 0px 6px;
    }

    .btn-collapse:hover {
        opacity: 0.8;
    }
    
    .panel-content {
        width: 370px;
        background-color: white;
        transition: width 0.4s ease-in-out;
        height: 80vh;
        overflow: hidden;
        border-radius: 5px;
        gap: 18px;
        display: flex;
        flex-direction: column;
        padding: 20px 16px;
        overflow-y: auto;
    }

    .panel-content-collapse {
        width: 0 !important;
    }

    &.main-panel-collapsed {
        right: 0;
    }

    @media (max-width: 768px) {  /* Tablets */
        .panel-content {
            width: 50vw;
        }
    }
    
    @media (max-width: 480px) {  /* Phones */
        .panel-content {
            width: 85vw;

        }
    }

    @media (min-width: 1024px) {  /* Large laptops */
        .panel-content {
            width: 370px;
            height: 75vh;
        }
    }
    
    @media (min-width: 1440px) {  /* Large laptops */
        .panel-content {
            width: 370px;
        }
    }

    @media (min-width: 1512px) {  /* Large laptops */
        .panel-content {
            width: 450px;
        }
    }
    
    @media (min-width: 1920px) {  /* Full HD monitors */
        .panel-content {
            width: 450px;
        }
    }
    
    @media (min-width: 2560px) {  /* 2K and higher resolution screens */
        .panel-content {
            width: 370px;
        }
    }
}