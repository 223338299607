.report {
	.cardIcon {
		font-size: 64px;
		text-align: center;
		display: block;
	}
	.labels {
		font-size: 13px;
		text-align: center;
	}
	.speedSlider {
		margin-left: 35px;
		margin-right: 35px;
	}
}
.report-icon-color {
	color: #4987c6;
}
.menu-icon-color {
	color: #ffff;
}
.optionsView {
	.speedSlider {
		margin-left: 10px;
		margin-right: 20px;
	}
	.buttonReportSave {
		text-align: right;
		align-self: center;
		padding-top: 12px;
	}
	.buttonReport {
		align-self: center;
	}
}
.automaticReport {
	.ant-modal-body {
		min-height: 200px;
		height: auto;
	}
}
.report {
	.addReportButton {
		width: 100%;
		height: 287px;
	}
	.radioBtn {
		margin: 5px 0;
	}
	.smallChartWrapper {
		padding: 0 5px 5px 0;
	}
}

.chartTitle {
	text-align: center;
}

.chartConfig {
	width: 100%;
	padding: 5px 0px;
}

.reportTitle {
	text-align: center;
	width: 100%;
	font-size: 20px;
	color: #565656;
	.sentenceCase {
		@include sentence-case();
	}
}

.generalChartTitle {
	text-align: center;
}

.Dasboard {
	.widgetCard {
		padding: 8px;
	}
	.ant-card-bordered {
		border-color: #1890ff;
	}
	.filterOptions {
		padding-bottom: 6px;
	}
	.ant-card-body {
		min-height: 392px;
	}
	.evensTimeline {
		min-height: 804px;
	}
}

.dragger-list {
	background-color: #f0f2f5;
	padding: 8px;
	border-radius: 10px;
}
.EditDashboard {
	.dragIcon {
		padding-right: 10px;
		font-size: 26px;
	}
	.preview {
		padding-top: 5px;
		padding-left: 10px;
		font-size: 16px;
	}
	.ant-modal-content {
		width: 100%;
		.divWidget {
			max-height: 600px;
			overflow-y: auto;
		}
		padding: 5px;
	}
	.ant-card {
		width: 100%;
	}
	.ant-col {
		padding-top: 5px;
	}
	.ant-card-body {
		padding: 5px !important;
		max-height: 392px;
	}
}
.standarWidgetStyle {
	height: 100%;
	min-height: 306px;
}

.evensTimeline {
	min-height: 705px;
}

.tableWidget {
	min-height: 394px;
	padding-bottom: 0;
}

.chartWrapper {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	margin: 0 4px 8px 4px;
}

.emptyReportsContainer {
	position: 'relative';
	height: calc(100vh - 270px);
	display: block;
}

.emptyReports {
	position: absolute !important;
	top: calc(50% - 60px);
	left: calc(50% - 65px);
	display: block;
}

.spinContainer {
	position: relative;
	height: calc(100vh - 270px);
	display: block;
}

.gasComsuptionSpin {
	position: absolute !important;
	top: calc(50% - 23px);
	left: calc(50% - 29.5px);
	display: block;
}

.photos {
	margin: 9px 0 9px 0;
}

.photosContainer {
	display: flex;
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	overflow: auto;
	padding: 8px 24px;
	height: 65vh;
}

.photoCard {
	min-height: 320px;
}

.mileage-per-state-report {
	&-options {
		padding-top: 10px;
		padding-bottom: 20px;
	}
}

.posted-speed-limit {
	&.report.view {
    	height: 100%;
	}
	&-content {
		height: calc(100% - 120px);
		.premium-acccess {
			padding-top: 10px;
			padding-bottom: 34px;
		}
	}
	&-chart {
		padding-top: 10px;
		padding-bottom: 20px;
	}
}
