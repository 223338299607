.panel-sections {
    top: 70px;

    &-clickable-icon {
        cursor: pointer;
    }
    &-label {
        text-transform: capitalize;
        cursor: default;
    }
    &-dropdown {
        .ant-collapse-header {
            background: #ffffff;
            color: #484848 !important;
            cursor: default !important;
            h5.ant-typography {
                margin: 0;
            }
        }   
    }
    .sensor-panel {
        &-icon {
            font-size: 50px;
        }
        &-label {
            color: #484848;
            text-align: center;
        }
        &-content {
            min-height: 72px;
        }
        &-empty {
            margin: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
    .status-panel {
        &-date-label {
            text-align: end;
        }
        &-last-report {
            margin-bottom: 6px;
        }
    }
}
