.premium-feature-card {
    box-shadow: 1px 2px 11px #3434341c;
    border: 0;
    justify-self: center;
    width: 44rem;

    &-item {
        justify-self: center;
    }
    &-container {
        width: 100%;
        text-align: center;
    }
    &-icon {
        width: 10rem;
        margin: 0px auto;
    }
    &-bullets {
        justify-self: center;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
    &-bullet-point {
        display: flex;
        align-items: center;
    }
    &-bullet {
        color: #389E0D;
        font-size: clamp(1.25rem, 2vw, 1.75rem);
        padding-top: 1px;
        margin-right: 5px;
    }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
    .premium-feature-card {
        width: 100%;
        &-bullet-point {
            align-items: flex-start;
            text-align: left;
        }
    }
}
